<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="toHome()" />
      </van-sticky>
      <div class="detail">
        <h2 style="text-align: center" v-if="this.type != 104">
          {{ courses.title }}
        </h2>
        <div class="acontent">上课老师： {{ courses.teacherUserName }}</div>
        <div class="acontent">上课地点： {{ courses.address }}</div>
        <div class="acontent">上课时间： {{ courses.startTime }}</div>
        <div v-if="(courses.qrStartTime!=undefined&& courses.qrStartTime!='')" class="acontent">签到开始时间： {{ courses.qrStartTime }}</div>
        <div v-if="(courses.qrEndTime!=undefined&& courses.qrEndTime!='')" class="acontent">签到结束时间： {{ courses.qrEndTime }}</div>
        <div class="acontent">课程时长： {{ courses.courseLength }} 小时</div>
        <div class="acontent">课程简介： {{ courses.summary }}</div>
      </div>
      <div v-if="!hassign" class="btn-box">
      <van-button type="info" size="large" round block @click="onSign"
        >登记签到</van-button
      >
    </div>
    <div v-if="hassign" class="btn-box">
       <h2 style="text-align: center; color:rgb(157, 29, 34)">
          您已登记签到
        </h2>
    </div>
    </div>
    
  </div>
</template>

<script>
import { Toast } from "vant";
import { getCourseOffline } from "@/api/courseoffline";
import { AddBrowsingLog, QueryBrowsingLog } from "@/api/browsingLog";

export default {
  name: "znarticledetail",
  data() {
    return {
      loading: true,
      type: 100,
      title: "",
      hassign: false,
      courses: {},
    };
  },
  components: {},

  created() {
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/u/sign") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    toHome() {
      window.location.replace(window.location.origin);
    },
    toProduct(item) {
      this.$router.push({
        path: "/article",
        query: { type: item.typeId, productId: item.productId },
      });
    },
    async onLoad() {
      let para = this.$route.query.p;

      let bresult = await QueryBrowsingLog({
        id: 0,
        itemId: para,
        itemType: 9,
        currentTime: 0,
        totalTime: 0,
      });
     
      if (bresult.data.success) {
        this.hassign = true;
      } else {
        this.hassign = false;
      }

      let aresult = await getCourseOffline(para); //({ ...this.page })
      this.courses = aresult.data.data;
      this.title = "上课登记签到";

      this.loading = false;
    },
    async onSign(){
      let result=await   AddBrowsingLog({
            itemId: this.courses.id,
            itemType: 9,
          });
        if (result.data.success) {
             Toast("签到成功");
             this.hassign = true;
        }
        else{
          if(result.data.errorMessage)
          {
            Toast(result.data.errorMessage);
          }
          else
          {
            Toast("签到失败");
          }
        }
    }
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  padding: 5px 5px 5px 5px;
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.btn-box {
  padding: 10px 5px 5px 5px;
}
</style>